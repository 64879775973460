import { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'

import { Typography } from '@mui/material'

import { getSelectedAssets, useCartContext } from '@/contexts/CartContext'

export const DownloadButton = () => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.actionsBar',
  })

  const { state } = useCartContext()
  const selectedAssets = getSelectedAssets(state)

  const [assetsCount, setAssetsCount] = useState(0)

  useEffect(() => {
    setAssetsCount(selectedAssets.length)
  }, [selectedAssets.length])

  return (
    <Typography noWrap variant="body2" color="text.secondary" data-cy="files-selected-label">
      {/* TODO why cannot i18next figure out the correct key for pl-pl? */}
      {t(assetsCount === 1 ? 'filesSelected_one' : 'filesSelected_other', {
        count: assetsCount,
      })}
    </Typography>
  )
}
