const isEmpty = <T>(value: T | undefined | null): value is undefined | null => {
  if (typeof value === 'number' || typeof value === 'boolean') {
    return false
  }
  if (typeof value === 'undefined' || value === null) {
    return true
  }
  if (value instanceof Date) {
    return false
  }
  if (value instanceof Object && !Object.keys(value).length) {
    return true
  }
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return true
    }
    if (value.every((item) => isEmpty(item))) {
      return true
    }
  }
  if (<any>value === '') {
    return true
  }

  return false
}

export const isNotEmpty = <T>(value: T | undefined): value is T => !isEmpty(value)
