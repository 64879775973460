import { useTranslation } from 'next-i18next'

import { Button } from '@mui/material'

import {
  clearAllSelectedAssets,
  closeCart,
  getSelectedAssetIds,
  useCartContext,
} from '@/contexts/CartContext'

export const ClearButton = () => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.assetsCart',
  })
  const { state, dispatch } = useCartContext()
  const selectedAssetIds = getSelectedAssetIds(state)

  const handleUnselectAll = () => {
    dispatch(clearAllSelectedAssets())
    dispatch(closeCart())
  }

  const styles = {
    padding: '0',
    height: '40px',
  }

  return (
    <Button
      size="medium"
      variant="text"
      fullWidth
      onClick={handleUnselectAll}
      disabled={!selectedAssetIds.length}
      data-cy="DownloadDocument-Selected-DeleteAll"
      sx={styles}
    >
      {t('buttons.clear')}
    </Button>
  )
}
