import dynamic from 'next/dynamic'

import type { Theme } from '@mui/material'
import { Stack, useMediaQuery } from '@mui/material'

import { SearchBox } from '@/components/SearchBox'

const DynamicFiltersButton = dynamic(() =>
  import('@/components/FiltersButton').then((m) => m.FiltersButton),
)

export const SearchbarAndFiltersButton = () => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))

  return (
    <Stack flexDirection={{ xs: 'column', sm: 'row-reverse' }} gap={{ xs: 2, sm: 3 }}>
      {!isDesktop && <DynamicFiltersButton />}
      <SearchBox />
    </Stack>
  )
}
