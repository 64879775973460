import { DOWNLOAD_BUTTON_DATACY, SINGLE_DOCUMENT_DOWNLOAD_BUTTON_DATACY } from '@/constants'

export const getActionForDownloadEvent = (event: React.MouseEvent<HTMLElement>): string => {
  const { innerText } = event.currentTarget

  if (innerText) return innerText

  const dataCy = event.currentTarget.getAttribute('data-cy')

  if (dataCy === DOWNLOAD_BUTTON_DATACY) {
    return 'download_icon_multi'
  }

  if (dataCy?.startsWith(SINGLE_DOCUMENT_DOWNLOAD_BUTTON_DATACY)) {
    return 'download_icon_single'
  }

  return ''
}
