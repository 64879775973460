import type { Theme } from '@mui/material'
import { Box } from '@mui/material'
import type { SxProps } from '@mui/system'

import { CartList } from './CartList'
import { ClearButton } from './ClearButton'

const styles = {
  container: {
    width: '400px',
    position: 'absolute',
    zIndex: 1,
    borderRadius: '2px 0',
    backgroundColor: (theme) => theme.palette.background.paper,
    boxShadow: (theme) => theme.shadows[1],
  } as SxProps<Theme>,
}

export const CartBody = () => {
  return (
    <Box sx={styles.container}>
      <CartList />
      <ClearButton />
    </Box>
  )
}
