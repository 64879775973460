import { Clear } from '@mui/icons-material'
import { IconButton, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'

type CartItemProps = {
  name: string
  onClick: () => void
  dataCySuffix?: string
}

const DATA_CY_PREFIX = 'DownloadDocument-Selected'

export const CartItem: React.FC<CartItemProps> = ({ name, onClick, dataCySuffix }) => {
  return (
    <ListItem
      disablePadding
      secondaryAction={
        <IconButton
          edge="end"
          color="secondary"
          size="small"
          onClick={onClick}
          data-cy={`${DATA_CY_PREFIX}-Delete-${dataCySuffix}`}
        >
          <Clear />
        </IconButton>
      }
    >
      <ListItemButton dense>
        <ListItemText
          primary={
            <Typography
              color="text.secondary"
              display="block"
              variant="body3dense"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              width="350px"
              data-cy={`${DATA_CY_PREFIX}-Name-${dataCySuffix}`}
            >
              {name}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  )
}
