import logger from '@knauf-group/ct-shared-nextjs/logger'

import { ANALYTICS_STRING_SEPARATOR } from '@/constants'

export const getLanguageNameFromCode = (codes: string | undefined) => {
  if (!codes) return ''

  const languageNames = new Intl.DisplayNames(['en'], {
    type: 'language',
  })

  const codeNames: string[] = []

  codes.split(ANALYTICS_STRING_SEPARATOR).forEach((code) => {
    try {
      const codeName = languageNames.of(code)?.toLowerCase()

      if (codeName) {
        codeNames.push(codeName)
      }
    } catch (e) {
      logger.error(`invalid language code: ${code}`, e)
    }
  })

  return codeNames.join(ANALYTICS_STRING_SEPARATOR)
}
