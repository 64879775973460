import { useState } from 'react'

import { useSearchBoxWithDebounce } from 'src/hooks/useSearchBoxWithDebounce'
import { useDeepCompareMemo } from 'use-deep-compare'

import { SearchContext } from '@/contexts/SearchContext/SearchContext'

export const SearchProvider = ({ children }) => {
  const { query } = useSearchBoxWithDebounce()
  const [internalQuery, setInternalQuery] = useState(query)

  return (
    <SearchContext.Provider
      value={useDeepCompareMemo(() => ({ internalQuery, setInternalQuery }), [internalQuery])}
    >
      {children}
    </SearchContext.Provider>
  )
}
