import { Box, List } from '@mui/material'

import {
  clearSelectedAsset,
  closeCart,
  getSelectedAssets,
  useCartContext,
} from '@/contexts/CartContext'

import { CartItem } from './CartItem'

const styles = {
  border: '0',
  borderBottom: '1px solid #e0e0e0',
  overflowY: 'auto',
  pt: 1,
  pb: 1,
}

export const CartList = () => {
  const { state, dispatch } = useCartContext()
  const assets = getSelectedAssets(state)

  const handleItemRemoval = (assetId: string) => {
    if (assets.length === 1) {
      dispatch(closeCart())
    }

    dispatch(clearSelectedAsset(assetId))
  }

  return (
    <Box>
      <List dense sx={styles}>
        {assets.map(({ name, assetId }, idx) => (
          <CartItem
            key={assetId}
            name={name}
            onClick={() => handleItemRemoval(assetId)}
            dataCySuffix={`${idx}`}
          />
        ))}
      </List>
    </Box>
  )
}
