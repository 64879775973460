import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { Breadcrumbs } from '@knauf-group/ct-designs/components/core/Breadcrumbs'
import type { IAppsConfig } from '@knauf-group/ct-shared-nextjs/client'
import { WebFooter } from '@knauf-group/ct-shared-nextjs/components/WebFooter'
import logger from '@knauf-group/ct-shared-nextjs/logger'
import type { SharedContentfulProps } from '@knauf-group/ct-shared-nextjs/web'
import { useClickEvent } from '@knauf-group/ct-shared-nextjs/web/hooks'
import { Container, Stack, Toolbar } from '@mui/material'
import { ANALYTICS_CONSTS } from 'src/hooks/analytics/const'

import { BREADCRUMBS_LINKS } from '@/constants'

import { WebHeader2 } from './WebHeader2'

export type LayoutProps = SharedContentfulProps & {
  appsConfig: IAppsConfig
  headInfo: {
    pageCanonicalHref: string
    pageDescription: string
    pageTitle: string
  }
}

const DynamicScrollTop = dynamic(() => import('./ScrollTop'))

export const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  appsConfig,
  children,
  footerEntries,
  headInfo: { pageCanonicalHref, pageDescription, pageTitle },
  headerEntries,
}) => {
  const router = useRouter()

  const { push: pushClickEvent } = useClickEvent()

  const { t } = useTranslation('download-center')
  const { t: tSharedNextjs } = useTranslation('shared-nextjs')
  const { t: tCMS } = useTranslation('cms')

  const toggleCookiesBanner = () => {
    try {
      // @ts-expect-error we don't know OneTrust type, but we know it has ToggleInfoDisplay
      window.OneTrust.ToggleInfoDisplay()
    } catch (error: any) {
      // most of the time the error happens because the browser blocks all the cookies
      logger.error(error?.message)
    }
  }

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="shortcut icon" href="https://knauf.com/favicon.ico" />
        <link rel="canonical" href={pageCanonicalHref} />
      </Head>
      <Stack sx={{ minHeight: '100vh' }}>
        <WebHeader2
          analytics={{ eventPayload: { page_name: ANALYTICS_CONSTS.page_name } }}
          appsConfig={appsConfig}
          navigationLinks={headerEntries || []}
          onClickLogo={() => {
            pushClickEvent({
              reference: { label: 'logo', href: '/', external: false },
              type: 'link',
              eventPayload: {
                page_name: ANALYTICS_CONSTS.page_name,
                target: '/',
                page_section: 'header',
              },
            })

            router.push('/')
          }}
          closeMobileDrawerA11yLabel={tSharedNextjs('drawer.close-drawer')}
        />
        <Toolbar />

        <Stack component={Container}>
          <Breadcrumbs
            links={BREADCRUMBS_LINKS}
            backLabel={t('common.navigation.buttons.back')}
          />
          <Stack component="main" flex={1}>
            {children}
          </Stack>

          <DynamicScrollTop />
        </Stack>

        {/* @ts-expect-error TODO `WebFooter` types are stricter than `footerEntries` */}
        <WebFooter
          {...footerEntries}
          languageText={tCMS('common.languageSelector')}
          cookieSetting={{
            onClick: toggleCookiesBanner,
            cookieSettingLabel: tCMS('common.cookies.cookiesSettings'),
          }}
          analytics={{ eventPayload: { page_name: ANALYTICS_CONSTS.page_name } }}
        />
      </Stack>
    </>
  )
}
