import { useSortBy } from 'react-instantsearch-core'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import env from '@beam-australia/react-env'
import type { SortByItem } from 'instantsearch.js/es/connectors/sort-by/connectSortBy'
import { isNotEmpty } from 'src/utils/plain/isEmpty'
import { splitLocale } from 'src/utils/plain/splitLocale'

const generateAlgoliaIndexName = ({
  country,
  sortBy,
}: {
  country: string
  sortBy?: string
}) => {
  let indexName = `${env('ALGOLIA_INDEX_PREFIX')}_${country.toLowerCase()}`

  if (isNotEmpty(sortBy)) {
    indexName = `${indexName}_${sortBy}`
  }

  return indexName
}

const generateSortByItems = (locale: string): SortByItem[] => {
  const { country } = splitLocale(locale)

  // label is a t key for `mainPage.filtersPanel.filters.sort-by`
  return [
    {
      label: 'name-asc',
      value: generateAlgoliaIndexName({ country }),
    },
    {
      label: 'name-desc',
      value: generateAlgoliaIndexName({ country, sortBy: 'name_desc' }),
    },
    {
      label: 'last-updated-desc',
      value: generateAlgoliaIndexName({ country, sortBy: 'last_updated_desc' }),
    },
  ]
}

export const useNormalizedSortBy = () => {
  const { locale } = useRouter()
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.filtersPanel.filters.sort-by',
  })
  const items = generateSortByItems(locale)

  const sortBy = useSortBy({
    items: items.map((item) => ({
      label: t(item.label),
      value: item.value,
    })),
  })

  return {
    ...sortBy,
    originalItems: items,
  }
}
