import { useTranslation } from 'next-i18next'

import { SaveAltOutlined } from '@mui/icons-material'
import type { Theme } from '@mui/material'
import { Button, useMediaQuery } from '@mui/material'

import { CART_DOWNLOAD_BUTTON_DATACY } from '@/constants'
import { getSelectedAssets, useCartContext } from '@/contexts/CartContext'
import { useDownloadAssets } from '@/hooks'

export const DownloadButton = () => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.assetsCart',
  })

  const { state } = useCartContext()
  const assets = getSelectedAssets(state)

  const isScreenMdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))

  const shouldShowSelectionCount = !isScreenMdUp && assets.length > 0

  let buttonText = t('buttons.download')

  if (shouldShowSelectionCount) buttonText += ` (${assets.length})`

  const { href, handleOnClick } = useDownloadAssets(state, assets)

  return (
    <Button
      size="medium"
      startIcon={<SaveAltOutlined />}
      fullWidth
      data-cy={CART_DOWNLOAD_BUTTON_DATACY}
      href={href}
      onClick={handleOnClick}
      variant="contained"
      disabled={assets.length === 0}
    >
      {buttonText}
    </Button>
  )
}
