import { useInfiniteHits } from 'react-instantsearch-core'
import dynamic from 'next/dynamic'

import type { Theme } from '@mui/material'
import { Box, Divider, Paper, Stack, useMediaQuery } from '@mui/material'

import { AssetsCart } from '@/components/AssetsCart'
import { DownloadButton } from '@/components/DownloadButton'
import { STICKY_DOWNLOAD_BUTTON_PAPER_HEIGHT } from '@/constants'
import { getSelectedAssets, useCartContext } from '@/contexts/CartContext'
import type { AssetModel } from '@/types'

const DynamicNoRowsOverlay = dynamic(() =>
  import('./NoRowsOverlay').then((m) => m.NoRowsOverlay),
)

const DynamicSearchResultsTable = dynamic(() =>
  import('@/components/SearchResultsTable').then((m) => m.SearchResultsTable),
)

const DynamicShowMoreWidget = dynamic(() =>
  import('@/components/ShowMoreWidget').then((m) => m.ShowMoreWidget),
)

export const SearchResults = () => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))

  const { state } = useCartContext()
  const assets = getSelectedAssets(state)

  const { isLastPage, items, results, sendEvent, showMore } = useInfiniteHits<AssetModel>()

  return (
    <Stack gap={2}>
      {isDesktop && (
        <Stack gap={0}>
          <Stack direction="row" gap={2} p={2} alignItems="center">
            <AssetsCart />
            <DownloadButton />
          </Stack>
        </Stack>
      )}
      {/* TODO show skeleton when status == loading? */}
      {items.length === 0 ? (
        <DynamicNoRowsOverlay />
      ) : (
        <>
          <DynamicSearchResultsTable
            items={items}
            results={results}
            sendAlgoliaEvent={sendEvent}
          />
          <DynamicShowMoreWidget
            isLastPage={isLastPage}
            results={results}
            showMore={showMore}
          />
        </>
      )}
      {!isDesktop && assets.length > 0 && (
        <Paper
          elevation={16}
          sx={{
            position: 'sticky',
            bottom: '0px',
            mx: { xs: -2, sm: -4 },
            height: `${STICKY_DOWNLOAD_BUTTON_PAPER_HEIGHT}px`,
            width: '100vw',
          }}
        >
          <Stack gap={2}>
            <Divider />
            <Box sx={{ p: 0, px: 2, m: 0 }}>
              <AssetsCart />
            </Box>
          </Stack>
        </Paper>
      )}
    </Stack>
  )
}
