import type { PropsWithChildren } from 'react'
import type { InstantSearchServerState } from 'react-instantsearch'
import { Configure, InstantSearch, InstantSearchSSRProvider } from 'react-instantsearch'
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs'
import singletonRouter from 'next/router'

import type { DeviceType } from '@knauf-group/ct-shared-nextjs/utils/deviceType'
import type { IndexUiState, UiState } from 'instantsearch.js'
import type { RouterProps } from 'instantsearch.js/es/middlewares'

import { getAlgoliaIndexName, searchClient } from '@/services/algolia'

import { createURL, parseURL, routeToState, stateToRoute } from '../utils/plain/algolia'
import { splitLocale } from '../utils/plain/splitLocale'

export type AlgoliaProviderProps = {
  serverState?: InstantSearchServerState
  serverUrl: string
  locale: string
  deviceType: DeviceType | undefined
}

export const AlgoliaProvider = (props: PropsWithChildren<AlgoliaProviderProps>) => {
  const { serverUrl, serverState, locale, children, deviceType } = props

  const { language, country } = splitLocale(locale)
  const indexName = getAlgoliaIndexName(country)

  const routing: RouterProps<UiState, IndexUiState> = {
    router: createInstantSearchRouterNext({
      singletonRouter,
      serverUrl,
      routerOptions: {
        cleanUrlOnDispose: true,
        // THIS IS CALLED FOURTH
        createURL: createURL({ indexName, language }),
        // THIS IS CALLED FIRST
        parseURL: parseURL({ indexName, language }),
      },
    }),
    stateMapping: {
      // THIS IS CALLED THIRD
      stateToRoute: stateToRoute({ indexName }),
      // THIS IS CALLED SECOND
      routeToState: routeToState({ indexName }),
    },
  }

  return (
    <InstantSearchSSRProvider {...serverState}>
      <InstantSearch
        indexName={indexName}
        searchClient={searchClient}
        routing={routing}
        insights
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure
          analytics
          analyticsTags={['reference:download-center', `device:${deviceType}`]}
          hitsPerPage={deviceType === 'mobile' ? 10 : undefined}
        />
        {children}
      </InstantSearch>
    </InstantSearchSSRProvider>
  )
}
