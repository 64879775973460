// NOTE: this file should not contain any import alias, as it's used in next.config.ts
//  please use relative path

export const ALGOLIA_NAME_ASC_SORT_INDEX_SUFFIX = 'name_asc'
export const ALGOLIA_NAME_DESC_SORT_INDEX_SUFFIX = 'name_desc'

// Record<key how it is in algolia, object with details>
export const ALGOLIA_FACETS = {
  documentTypes: {
    facet: 'documentTypes',
    urlParam: 'documentTypes',
    t: 'documentType',
  },
  storeAvailability: {
    facet: 'storeAvailability',
    urlParam: 'store-Availability',
    t: 'storeAvailability',
  },
  fieldsOfApplication: {
    facet: 'fieldsOfApplication',
    urlParam: 'field-of-application',
    t: 'fieldOfApplication',
  },
  areasOfApplication: {
    facet: 'areasOfApplication',
    urlParam: 'area-of-application',
    t: 'areaOfApplication',
  },
  division: {
    facet: 'division',
    urlParam: 'division',
    t: 'division',
  },
  productCategories: {
    facet: 'productCategories.lvl0',
    urlParam: 'product-categories',
    t: 'productCategories',
  },
  language: {
    facet: 'language',
    urlParam: 'language',
    t: 'language',
  },
} as const

// TODO: check how to use only ALGOLIA_FACETS_MAP without the need of ALGOLIA_FACETS
export const ALGOLIA_FACETS_MAP = {
  refinementList: {
    [ALGOLIA_FACETS.productCategories.facet]: {
      t: ALGOLIA_FACETS.productCategories.t,
      urlParam: ALGOLIA_FACETS.productCategories.urlParam,
      hasLanguageSuffix: true,
    },
    [ALGOLIA_FACETS.fieldsOfApplication.facet]: {
      t: ALGOLIA_FACETS.fieldsOfApplication.t,
      urlParam: ALGOLIA_FACETS.fieldsOfApplication.urlParam,
      hasLanguageSuffix: true,
    },
    [ALGOLIA_FACETS.areasOfApplication.facet]: {
      t: ALGOLIA_FACETS.areasOfApplication.t,
      urlParam: ALGOLIA_FACETS.areasOfApplication.urlParam,
      hasLanguageSuffix: true,
    },
    [ALGOLIA_FACETS.division.facet]: {
      t: ALGOLIA_FACETS.division.t,
      urlParam: ALGOLIA_FACETS.division.urlParam,
    },
    [ALGOLIA_FACETS.storeAvailability.facet]: {
      t: ALGOLIA_FACETS.storeAvailability.t,
      urlParam: ALGOLIA_FACETS.storeAvailability.urlParam,
      hasLanguageSuffix: true,
    },
    [ALGOLIA_FACETS.documentTypes.facet]: {
      t: ALGOLIA_FACETS.documentTypes.t,
      urlParam: ALGOLIA_FACETS.documentTypes.urlParam,
    },
    [ALGOLIA_FACETS.language.facet]: {
      t: ALGOLIA_FACETS.language.t,
      urlParam: ALGOLIA_FACETS.language.urlParam,
    },
  },
} as const

const SEARCH_QUERY_KEY = 'query' as const
const SORT_KEY = 'order' as const

export type FacetKeyExceptCategory = Exclude<keyof typeof ALGOLIA_FACETS, 'productCategories'>
export type UrlParam = (typeof ALGOLIA_FACETS)[FacetKeyExceptCategory]['urlParam']
export type Facet = (typeof ALGOLIA_FACETS)[FacetKeyExceptCategory]['facet']
export type RefinementListKeys = keyof (typeof ALGOLIA_FACETS_MAP)['refinementList']
export type RefinementListQueryParams =
  (typeof ALGOLIA_FACETS_MAP)['refinementList'][RefinementListKeys]['urlParam']

export const HIERARCHICAL_LEVELS_SEPARATOR = ' > '

// TODO should be coming from the index configuration and not being hardcoded
export const maxFacetHits = 50

export type QueryParams = Partial<
  // refinement list (mulstiselect)
  Record<RefinementListQueryParams, string[]> & {
    // search query and sort by
    [SEARCH_QUERY_KEY]: string
    [SORT_KEY]: string
  }
>
