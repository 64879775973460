import { useCurrentRefinements } from 'react-instantsearch-core'

import logger from '@knauf-group/ct-shared-nextjs/logger'

import type { SelectedAsset } from '@/types'

import {
  defaultValues,
  getAssetProperties,
  useGetCustomPropertiesFromAlgolia,
  useGetFilterProperties,
} from './propertiesHelpers'
import { useInitializeAnalytics } from './useInitializeAnalytics'

export const useAnalyticsTracking = () => {
  const { analytics, isReady } = useInitializeAnalytics()
  const customProperties = useGetCustomPropertiesFromAlgolia()
  const customFilterProperties = useGetFilterProperties()

  if (!isReady) {
    const warnFn = () =>
      logger.warn('Trigger analytics tracking before initialization is ready')

    return {
      trackFilterEvent: warnFn,
      trackClickEvent: warnFn,
      trackDownload: warnFn,
    }
  }

  const trackClickEvent = (
    action: string,
    target: string,
    eventSpecification: 'internal_cta_click' | 'external_cta_click',
  ) => {
    analytics.track('click', {
      ...defaultValues,
      ...customProperties,
      ...customFilterProperties,
      action,
      target,
      event_specification: eventSpecification,
    })
  }

  const trackFilterEvent = (filterName: string, filterValue: string) => {
    analytics.track('filter', {
      ...defaultValues,
      ...customProperties,
      filter_name: filterName,
      filter_value: filterValue,
    })
  }

  const trackDownload = (action: string, target: string, files: SelectedAsset[]) => {
    const assetProperties = getAssetProperties(files)

    analytics.track('download', {
      action,
      target,
      ...defaultValues,
      ...customProperties,
      ...customFilterProperties,
      ...assetProperties,
    })
  }

  return {
    trackDownload,
    trackFilterEvent,
    trackClickEvent,
  }
}

export const useAnalyticsTrackResetFilters = ({
  includedAttributes,
  excludedAttributes,
}: {
  includedAttributes?: string[]
  excludedAttributes?: string[]
}) => {
  const { analytics, isReady } = useInitializeAnalytics()
  const customProperties = useGetCustomPropertiesFromAlgolia()

  const { items } = useCurrentRefinements({ includedAttributes, excludedAttributes })

  if (!isReady) {
    const warnFn = () =>
      logger.warn('Trigger analytics tracking before initialization is ready')

    return {
      trackResetFiltersEvent: warnFn,
    }
  }

  const trackResetFiltersEvent = (action: string) => {
    const itemsRefined = items.filter((i) => i.refinements.length)
    const attributes = itemsRefined.map((i) => i.attribute)
    const values = itemsRefined.flatMap((i) => i.refinements.map((r) => String(r.value)))

    analytics.track('reset', {
      ...defaultValues,
      ...customProperties,
      action,
      filter_name: attributes.join(';'),
      filter_value: values.join(';'),
    })
  }

  return { trackResetFiltersEvent }
}
