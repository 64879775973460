import dynamic from 'next/dynamic'

import type { Theme } from '@mui/material'
import { Stack, useMediaQuery } from '@mui/material'

const DynamicFilterChips = dynamic(() =>
  import('@/components/FilterChips').then((m) => m.FilterChips),
)

const DynamicActiveFiltersChips = dynamic(() =>
  import('@/components/ActiveFiltersChips').then((m) => m.ActiveFiltersChips),
)

const DynamicNondesktopFilters = dynamic(() =>
  import('@/components/Filters/NondesktopFilters').then((m) => m.NondesktopFilters),
)

export const Filters = () => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))

  if (isDesktop) {
    return (
      <Stack gap={3}>
        <Stack direction="row" flexWrap="wrap" gap={2}>
          <DynamicFilterChips />
        </Stack>
        <DynamicActiveFiltersChips />
      </Stack>
    )
  }

  return <DynamicNondesktopFilters />
}
