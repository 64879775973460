import { createContext, useContext } from 'react'

export type SearchContextType = {
  internalQuery: string
  setInternalQuery: (query: string) => void
}

export const SearchContext = createContext<SearchContextType>({} as SearchContextType)

export const useSearchContext = () => {
  return useContext(SearchContext)
}
