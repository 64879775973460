import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'

import { useAnalytics } from '@knauf-group/cst-analytics'

export const useInitializeAnalytics = () => {
  const router = useRouter()
  const { status, data: session } = useSession()
  const isSessionReady = status !== 'loading'
  const analytics = useAnalytics(session)

  return {
    analytics,
    isReady: router.isReady && isSessionReady && analytics.isReady,
  }
}
