import { getDirectionAttribute } from '@knauf-group/ct-designs/utils/utils'

export const getLanguageAndCountry = (locale: string = '') => {
  const [languageCode = '', countryCode = ''] = locale.split('-')

  return {
    languageCode: languageCode.toLowerCase(),
    countryCode: countryCode.toLowerCase(),
  }
}

export const shouldUseRTL = (locale: string) => {
  return getDirectionAttribute(locale) === 'rtl'
}
