import type { Theme } from '@mui/material'
import { Box, useMediaQuery } from '@mui/material'

import {
  closeCart,
  getSelectedAssetIds,
  openCart,
  useCartContext,
} from '@/contexts/CartContext'

import { CartBody } from './CartBody'
import { DownloadButton } from './DownloadButton'

export const AssetsCart = () => {
  const { state, dispatch } = useCartContext()
  const assetIds = getSelectedAssetIds(state)

  // only show `CartBody` on desktop
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))

  return (
    <Box
      sx={{ width: 'auto' }}
      {...(isDesktop && {
        onMouseEnter: () => (assetIds.length > 0 ? dispatch(openCart()) : null),
        onMouseLeave: () => (assetIds.length > 0 ? dispatch(closeCart()) : null),
      })}
    >
      <DownloadButton />
      {state.isOpen && isDesktop && <CartBody />}
    </Box>
  )
}
